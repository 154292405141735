var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chell" }, [
    _vm.data.state !== "leave" &&
    _vm.data.state !== "un-availability" &&
    _vm.data.state !== "cancel"
      ? _c(
          "div",
          {
            staticClass: "template-wrapper",
            class: {
              borderBind: _vm.data.state === "draft",
              borderBindBooked:
                _vm.data.state === "draft" && _vm.data.user_id > 0
            },
            style: {
              background:
                _vm.data.applications_count !== 0 ? "#EFA12C" : _vm.data.color
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "time float-left w-75 ",
                staticStyle: { width: "50%", "padding-top": "0px" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.momentFormat(_vm.data.StartTime, "HH:mm") + " - "
                    ) +
                    " " +
                    _vm._s(_vm.momentFormat(_vm.data.EndTime, "HH:mm")) +
                    " "
                )
              ]
            ),
            _vm.data.applications_count || _vm.data.receivedSwapRequests
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-white font-weight-bold font-weight-700 float-right",
                    staticStyle: { "border-radius": "8px" }
                  },
                  [
                    _vm.data.applications_count !== 0
                      ? _c(
                          "span",
                          { staticClass: "black-badge" },
                          [
                            _c("feather-icon", { attrs: { icon: "UserIcon" } }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.data.applications_count))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.receivedSwapRequests &&
                    _vm.data.receivedSwapRequests.length > 0
                      ? _c("span", { staticClass: "black-badge" }, [
                          _c(
                            "span",
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RepeatIcon" }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.data.vacant_job_roles
              ? _c("div", { staticClass: "w-50 d-flex" }, [
                  _vm.data.vacant_job_roles.length === 1
                    ? _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(_vm.data.vacant_job_roles[0].job_role.name)
                        )
                      ])
                    : _vm.data.vacant_job_roles.length > 1
                    ? _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(_vm.data.vacant_job_roles[0].job_role.name) +
                            " ...+" +
                            _vm._s(_vm.data.vacant_job_roles.length - 1)
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _c("div", [
              _vm.data.role_id && _vm.data.role_id[0]
                ? _c(
                    "div",
                    {
                      staticClass: "float-left w-50 white-template",
                      staticStyle: { "margin-bottom": "2px" }
                    },
                    [
                      _c("div", { staticClass: "white-template-content" }, [
                        _vm.data.role_id.length === 1
                          ? _c("div", {}, [
                              _vm._v(_vm._s(_vm.data.role_id[0].label))
                            ])
                          : _vm.data.role_id.length > 1
                          ? _c("div", {}, [
                              _vm._v(
                                _vm._s(_vm.data.role_id[0].label) +
                                  "...+" +
                                  _vm._s(_vm.data.role_id.length - 1)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e()
            ]),
            _c("br"),
            _vm.data.is_pcn
              ? _c("div", { staticClass: "w-50 d-flex" }, [
                  _vm._v(_vm._s(_vm.data.pcn.name))
                ])
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.data.state === "cancel"
      ? _c(
          "div",
          {
            staticClass: "template-wrapper-cancel",
            class: { borderBind: _vm.data.state === "draft" },
            style: {
              background:
                _vm.data.applications_count !== 0 ? "#EFA12C" : _vm.data.color
            }
          },
          [
            _c("div", { staticClass: "time" }, [
              _c("del", [
                _vm._v(
                  _vm._s(_vm.momentFormat(_vm.data.StartTime, "HH:mm") + " - ")
                )
              ]),
              _c("del", [
                _vm._v(_vm._s(_vm.momentFormat(_vm.data.EndTime, "HH:mm")))
              ]),
              _c("del", [
                _vm.data.break_minutes !== 0 && _vm.data.break_minutes !== null
                  ? _c(
                      "span",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "font-size": "x-small" }
                      },
                      [_vm._v("(" + _vm._s(_vm.data.break_minutes) + " m)")]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", [
              _vm.data.break_minutes !== 0 && _vm.data.break_minutes !== null
                ? _c("span", { staticStyle: { "font-size": "x-small" } }, [
                    _vm._v(
                      " Cancelled by " +
                        _vm._s(_vm.data.user.first_name) +
                        " " +
                        _vm._s(_vm.data.user.last_name)
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "w-100", staticStyle: { width: "75%" } }, [
              _vm.data.vacant_job_roles
                ? _c("div", { staticClass: "w-50 d-flex" }, [
                    _vm.data.vacant_job_roles.length === 1
                      ? _c("div", { staticClass: "float-left" }, [
                          _vm._v(
                            _vm._s(_vm.data.vacant_job_roles[0].job_role.name)
                          )
                        ])
                      : _vm.data.vacant_job_roles.length > 1
                      ? _c("div", { staticClass: "float-left" }, [
                          _vm._v(
                            _vm._s(_vm.data.vacant_job_roles[0].job_role.name) +
                              " ...+" +
                              _vm._s(_vm.data.vacant_job_roles.length - 1)
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("div", [
                _vm.data.role_id && _vm.data.role_id[0]
                  ? _c(
                      "div",
                      { staticClass: "float-left w-50 white-template" },
                      [
                        _c("div", { staticClass: "white-template-content" }, [
                          _vm.data.role_id.length === 1
                            ? _c("del", [
                                _c("div", {}, [
                                  _vm._v(_vm._s(_vm.data.role_id[0].label))
                                ])
                              ])
                            : _vm.data.role_id.length > 1
                            ? _c("del", [
                                _c("div", {}, [
                                  _vm._v(
                                    _vm._s(_vm.data.role_id[0].label) +
                                      "...+" +
                                      _vm._s(_vm.data.role_id.length - 1)
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _c("br"),
              _vm.data.is_pcn
                ? _c(
                    "div",
                    {
                      staticStyle: { "margin-top": "2px", "margin-left": "2px" }
                    },
                    [_c("del", [_vm._v(_vm._s(_vm.data.pcn.name))])]
                  )
                : _vm._e()
            ]),
            _vm.data.vacant_job_roles
              ? _c("div", [
                  _vm.data.vacant_job_roles.length === 1
                    ? _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(_vm.data.vacant_job_roles[0].job_role.name)
                        )
                      ])
                    : _vm.data.vacant_job_roles.length > 1
                    ? _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(_vm.data.vacant_job_roles[0].job_role.name) +
                            " ...+" +
                            _vm._s(_vm.data.vacant_job_roles.length - 1)
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _c("div", { staticClass: "w-25", staticStyle: { width: "25%" } }, [
              _vm.data.applications_count !== 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-white font-weight-bold font-weight-700",
                      staticStyle: {
                        "margin-top": "4px",
                        "background-color": "black",
                        padding: "2px",
                        width: "57px",
                        "border-radius": "8px"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "p-1" },
                        [
                          _c("feather-icon", { attrs: { icon: "UserIcon" } }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.data.applications_count))
                          ])
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        )
      : _vm._e(),
    _vm.data.state === "leave"
      ? _c("div", { staticClass: "template-wrapper" }, [
          _c("div", { staticClass: "float-left " }, [
            _c(
              "svg",
              {
                attrs: {
                  fill: "none",
                  height: "15",
                  viewBox: "0 0 12 12",
                  width: "15",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M3 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M5 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M7 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                })
              ]
            )
          ]),
          _vm.data.is_day
            ? _c("p", { staticClass: " ml-2 text-dark" }, [_vm._v("Full Day")])
            : _vm._e(),
          _vm.data.state === "leave" && !_vm.data.is_day
            ? _c("p", { staticClass: " ml-2 text-dark" }, [
                _vm._v(
                  _vm._s(
                    _vm.momentFormat(_vm.data.StartTime, "HH:mm") +
                      " - " +
                      _vm.momentFormat(_vm.data.EndTime, "HH:mm")
                  )
                )
              ])
            : _vm._e(),
          _c("p", { staticClass: "text-dark" }, [
            _vm._v(_vm._s(_vm.data.reason))
          ])
        ])
      : _vm._e(),
    _vm.data.state === "un-availability"
      ? _c("div", { staticClass: "template-wrapper" }, [
          _c("span", { staticClass: "  text-dark" }, [
            _vm._v("User unavailable")
          ]),
          _c("p", { staticClass: "  text-dark" }, [
            _vm._v(
              _vm._s(
                _vm.momentFormat(_vm.data.StartTime, "HH:mm") +
                  " - " +
                  _vm.momentFormat(_vm.data.EndTime, "HH:mm")
              )
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="">
    {{ momentFormat(data.date, 'ddd, DD') }}



<!--      <p class="float-right"><span class="mytooltip tooltip-effect-1"> <span class="tooltip-item" @click="test()">-->
<!--         <svg fill="none" height="20" viewBox="0 0 12 12" width="20" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path-->
<!--            d="M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9"-->
<!--            stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path-->
<!--            d="M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z"-->
<!--            stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path d="M3 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path d="M5 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path d="M7 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--      </svg>-->
<!--      </span> <span style="z-index: 999999999999!important;"-->
<!--          class="tooltip-content clearfix">-->
<!--        <span class="text-white text-large">fuck</span>-->
<!--        <img src="https://i.imgur.com/y74Wb0q.png">-->
<!--        <span class="tooltip-text">Get the best way to find to eat icecream at BBBOOTSTRAP.COM</span>-->
<!--      </span> </span>-->
<!--      </p>-->



<!--    <button v-b-tooltip="{-->
<!--  content: msg,-->
<!--  placement: 'bottom-center',-->
<!--  classes: ['info'],-->
<!--  targetClasses: ['it-has-a-tooltip'],-->
<!--  offset: 100,-->
<!--  delay: {-->
<!--    show: 500,-->
<!--    hide: 300,-->
<!--  },-->
<!--}">Hover me</button>-->

    </div>



</template>

<script>
import MomentMixin from '@/mixins/MomentMixin'
import {BButton, VBTooltip,} from 'bootstrap-vue'

export default {
  name: 'dateHeaderTemplate',
  mixins: [MomentMixin],
  components:{
    VBTooltip
  },
  data(){
    return{
    msg:'jsjsjj'
    }
  },
  methods:{
    test(){
    }
  },

  mounted () {

 }

}
</script>

<style lang="scss" scoped>
//.tooltip {
//  // ...
//
//  &.info {
//    $color: rgba(#004499, .9);
//
//    .tooltip-inner {
//      background: $color;
//      color: white;
//      padding: 24px;
//      border-radius: 5px;
//      box-shadow: 0 5px 30px rgba(black, .1);
//      max-width: 250px;
//    }
//
//    .tooltip-arrow {
//      border-color: $color;
//    }
//  }
//}

body {
  background-color: #E0E0E0
}

.mt-100 {
  margin-top: 150px;
  margin-left: 200px
}

.card-header {
  background-color: #9575CD
}

h5 {
  color: #fff
}

.card-block {
  margin-top: 10px
}

.mytooltip {
  display: inline;
  position: relative;
  z-index: 999
}

.mytooltip .tooltip-item {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0 10px
}

.mytooltip .tooltip-content {
  position: absolute;
  z-index: 999999999999999;
  width: 360px;
  left: 50%;
  margin: 0 0 20px -180px;
  top: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  -webkit-box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  background: #2b2b2b;
  opacity: 0;
  cursor: default;
  pointer-events: none
}

.mytooltip .tooltip-content::after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #2a3035 transparent transparent;
  border-width: 10px;
  margin-left: -10px
}

.mytooltip .tooltip-content img {
  position: relative;
  height: 140px;
  display: block;
  float: left;
  margin-right: 1em
}

.mytooltip .tooltip-item::after {
  content: '';
  position: absolute;
  width: 360px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.mytooltip:hover .tooltip-item::after {
  pointer-events: auto
}

.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg)
}

.mytooltip:hover .tooltip-content2 {
  opacity: 1;
  font-size: 18px
}

.mytooltip .tooltip-text {
  font-size: 14px;
  line-height: 24px;
  display: block;
  padding: 1.31em 1.21em 1.21em 0;
  color: #fff
}

</style>
